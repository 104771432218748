import React from 'react';
import SubsPopup from './SubsPopup';
export default class Contact extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email:'',
          company:'',
          desYourIdea:'',
          formErrors: {name:'',email: '',company:'',Idea:''},
          nameValid: false,
          emailValid: false,
          companyValid: false,
          desYourIdeaValid: false,
          formValid: false
          };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateForm = this.validateForm.bind(this);
      }
   
    handleChange(e) { 
      const name = e.target.name;
      const value = e.target.value;
      //this.setState({[val]:e.target.value}); 
      this.setState({[name]: value},() => { this.validateField(name, value) });
     }
     validateField(fieldName, value) {
      let fieldValidationErrors = this.state.formErrors;
      let nameValid = this.state.nameValid;
      let emailValid = this.state.emailValid;
      let companyValid = this.state.companyValid;
      let desYourIdeaValid = this.state.desYourIdeaValid;
    
      switch(fieldName) {
        case 'name':
          nameValid = value.length >= 1;
          fieldValidationErrors.name = nameValid ? '': 'This field is required';
          break;
        case 'email':
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          fieldValidationErrors.email = emailValid ? '' : ' is invalid';
          break;
        case 'company':
            companyValid = value.length >= 1;
            fieldValidationErrors.company = companyValid ? '': 'This field is required';
            break;
        case 'desYourIdea':
          desYourIdeaValid = value.length >= 1;
          fieldValidationErrors.Idea = desYourIdeaValid ? '': 'This field is required';
          break;
        default:
          break;
      }
      this.setState({formErrors: fieldValidationErrors,
                      nameValid: nameValid,
                      emailValid: emailValid,
                      companyValid: companyValid,
                      desYourIdeaValid: desYourIdeaValid,
                    }, this.validateForm);
    }
    validateForm() {
      this.setState({formValid: this.state.nameValid && this.state.emailValid && this.state.companyValid && this.state.desYourIdeaValid});
      console.log('i am validartion',this.state.formValid);
    }
  handleSubmit(event) {
    document.getElementById('subs-txt-container').style.display='block';
    document.getElementById('subs-popup').style.display='block';
    document.getElementById('loader').style.display='block';
    document.getElementById('contact-txt').style.display='none';
    document.getElementById('newsletter-txt').style.display='none';
    //alert('A name was submitted: ' + this.state.value);
    event.preventDefault();
    fetch('https://thayakam-api.herokuapp.com/store', {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        
      })
         .then(response => response.json())
         .then((data) => {
            console.log('got');
              // document.getElementById('subs-popup').style.display='block';
              document.getElementById('subs-txt-container').style.display='block';
              document.getElementById('loader').style.display='none';
              document.getElementById('contact-txt').style.display='block';
              document.getElementById('newsletter-txt').style.display='none';

         
         })
         .catch((err) => {
          console.log('i am error');
          console.log(err.message);
        
         });
      
  }
    
    render(){
    return(
        <div className="contact-form-main">
          <div id="subs-popup" style={{display:'none'}}><SubsPopup /></div>
          
            <h2>Get in touch with us!</h2>
            <p id="tab-desk-contact-form-txt">Hey 👋, We are looking forward to hear your message! A team<br/>
            with innovative and extensive knoowledge is here to assist and<br/> 
            join the journey with you. We will touch you back as quickly as<br/>
            possible. have a good day! 😀

            </p>
            <p id="mob-contact-form-txt">Hey 👋, We are looking forward to hear<br/> 
            your message! A team with innovative <br/>
            and extensive knoowledge is here to<br/> 
            assist and join the journey with you.<br/>
            We will touch you back as quickly as<br/> 
            possible. have a good day! 😀
        
            </p>
            <div className="contact-form-container">
                <form className="contact-form" onSubmit={this.handleSubmit}>
                    <div><input type="text" id="name" name="name" placeholder="Name" value={this.state.name} onChange={this.handleChange} /></div>
                    <p>{this.state.formErrors.name}</p>
                    <div><input type="email" name="email" id="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} /></div>
                    <p>{this.state.formErrors.email}</p>
                    <div><input type="text" id="company" name="company" placeholder="Company" value={this.state.company} onChange={this.handleChange} /></div>
                    <p>{this.state.formErrors.company}</p>
                    <div><textarea  id="des-your-idea" name="desYourIdea" placeholder="Describe your Idea" value={this.state.desYourIdea} onChange={this.handleChange} ></textarea></div>
                    <p>{this.state.formErrors.Idea}</p>
                    <div className="contact-form-btn-container">
                        <div><input disabled={!this.state.formValid} type="submit" value="SEND"/></div>
                        <div><a href="https://wa.me/message/DWHRSHNA7ZJKC1" target="_blank" rel="noreferrer" >Reach Us via WhatsApp</a></div>
                    </div>
                </form> 
    
            </div>
   
        </div>
    );
    }
}