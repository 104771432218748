export default function SubsPopup(){
    const overlay={
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(0, 0, 0, 0.7)',
        transition: 'opacity 500ms',
        zIndex:1
        
      }
    const popup = {
        margin: '0 auto',
        padding: '20px',
        background:' #fff',
        borderRadius: '5px',
        width:'90%',
        position:' relative',
        transition: 'all 5s ease-in-out',
        top:'50%'
      }
      const close={
        position: 'absolute',
        top: 0,
        right:'10px',
        transition: 'all 200ms',
        fontSize: '30px',
        fontWeight: 'bold',
        textDecoration: 'none',
        color:' #333',
        cursor:'pointer'
      }
    function closeSubs(){
      document.getElementById('subs-popup').style.display='none';
    }
    return(
        <div>
        <div id="popup1" style={overlay}>
            <div className="popup" style={popup}>
            <div id="loader"></div>
            <div id="subs-txt-container">
                <a className="close" style={close} onClick={closeSubs}>&times;</a>
                <div className="content">
                  <p id="contact-txt" style={{margin:0,textAlign:'center'}}>Your query have been successfully submitted. We will contact you soon.<br/> Thank you.</p>
                  <p id="newsletter-txt" style={{margin:0,textAlign:'center'}}>Thank you! You have been subscribed successfully.</p>
                </div>
            </div> 
            </div>
        </div>
        </div>
    );
}