import img from "../images/Career/undraw_updated_resume_re_7r9j.svg";
export default  function Careers(){
    return(
        <div className="careers-main-container">
            <div className="careers-container">
                <h2>Join us and be a part of greatest journey! 🥳</h2>
                <div className="careers-wrapper">
                    <div>
                    <p>
                        We are a startup and a truly passionate team. We aim to provide a variety of
                        services to the clients. Therefore, we are looking for like-minded individuals who
                        inspire working with and learning new technologies and possess good logical
                        thinking and problem-solving skills. If you feel you are an eligible candidate for the
                        organization, send us your resume. We will be evaluating your application through
                        different stages.
                    </p>
                    </div>
                    <div >
                        <img className="careers-img" src={img} alt=""/>
                    </div>
                </div>
            </div>
            <div className="careers-msg">
                <p id="tab-desk-careers-msg-txt">
                We are looking forward to expand our teams to achieve great impacts. Send us ✉️<br/>
                    your resume by specifying  <span style={{fontWeight:500}}> Career - {'< Your Preferred job title >'} </span> . We will hold your<br/>
                    hands to fly.
                </p>
                <p id="mob-careers-msg-txt">
                We are looking forward to<br/> 
                expand our teams to achieve<br/> 
                great impacts. Send us ✉️ your<br/>
                resume by specifying <br/>
                <span style={{fontWeight:500}}> Career - {'< Your Preferred job title >'}</span><br/> 
                We will hold your hands to fly.
                </p>

                <div id="careers-icon">
                    <span>
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18.728" height="15" viewBox="0 0 18.728 15">
                                <path id="email" d="M16.842,1.75a1.872,1.872,0,0,1,1.886,1.886V14.864a1.873,1.873,0,0,1-1.886,1.886H1.886A1.82,1.82,0,0,1,.548,16.2,1.818,1.818,0,0,1,0,14.864V3.636A1.82,1.82,0,0,1,.548,2.3,1.818,1.818,0,0,1,1.886,1.75Zm0,3.772V3.636l-7.5,4.693L1.886,3.636V5.522l7.456,4.649Z" transform="translate(0 -1.75)"/>
                            </svg>
                        </i> 
                    </span>
                    <span>
                    &nbsp;careers@thaakayam.co.uk
                    </span>
                </div>
            </div>
        </div>
    );
}