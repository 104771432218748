import {useEffect} from 'react';
export default function Cookie(){
    /* common fuctions */
    useEffect(() => {
        function el(selector) { return document.querySelector(selector) }
        function els(selector) { return document.querySelectorAll(selector) }
        function on(selector, event, action) { els(selector).forEach(e => e.addEventListener(event, action)) }
        function cookie(name) { 
        let c = document.cookie.split('; ').find(cookie => cookie && cookie.startsWith(name+'='))
        return c ? c.split('=')[1] : false; 
        }

        on('.cookie-popup #accept', 'click', () => {
        el('.cookie-popup').classList.add('cookie-popup--accepted');
        document.cookie = `cookie-accepted=true`
        });

        on('.cookie-popup #deny', 'click', () => {
            el('.cookie-popup').classList.add('cookie-popup--accepted');
            document.cookie = `cookie-accepted=false`
            });

       
        if (cookie('cookie-accepted') !== "true") {
        el('.cookie-popup').classList.add('cookie-popup--not-accepted');
        }

    });
    return(
        <div className="container">
            <div className="cookie-popup cookie-popup--short cookie-popup--dark">
    
            <div>
            This website uses cookies to provide you with a great user experience. By using it, you accept our use of cookies
            </div>
                <div className="cookie-popup-actions">
                    <button id="deny">Deny</button>
                    <button id="accept">Accept</button>
                </div>
            </div>
        </div>
    );
}