import Navigation from './Navigation';
import Footer from './Footer';
import HomePage from '../pages/HomePage';
import WeArePage from '../pages/WeArePage';
import ServicesPage from '../pages/ServicesPage';
import CareersPage from '../pages/CareersPage';
import ContactPage from '../pages/ContactPage';
import Privacy from './Privacy';
import Cookie from './Cookie';
import {Routes,Route} from "react-router-dom";
function Layout(){
    return (
        <div>
            <Navigation/>
            <Routes>
                <Route path="*" element={<HomePage/>} />
                <Route path="/we-are" element={<WeArePage />} />
                <Route path='/services' element={<ServicesPage/>}/>
                <Route path='/careers' element={<CareersPage/>}/>
                <Route path='/contact' element={<ContactPage/>}/>
                <Route path='/privacy' element={<Privacy/>}/>
            </Routes>  
            <Footer />
            <Cookie/>
        </div>
      
    );

}

export default Layout
