import './App.css';
import './styles.css';
import './nav-footer-res.css';
import './animation.css';
import './mobile.css';
import './content-res.css'
import Layout from './components/Layout';
import { BrowserRouter} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
    <Layout/>
    </BrowserRouter>
    

  );
}

export default App;