export default function Privacy(){
    return(
        <div className="privacy-main-container">
            <h1>Data privacy and policy</h1>
            <div className="privacy-container">
                <h3>Personal Data Collected from you</h3>
                <div className="privacy-wrapper">
                    <p id="privacy-wrapper-sub-txt">We directly collect from you the personal data in the following ways:</p>
                    <div id="mob-privacy-txt-container">
                        <p>By entering information to Contact Us, we collect<br/>
                            your name, email address, country of residence,<br/> 
                            phone number, company you represent and details<br/> 
                            of the inquiry to enable us to respond to a general/<br/>
                            business inquiry made by you on behalf of your company.
                        </p>
            
                        <p>
                            You provide us with information during registrations.<br/>
                            To send you our newsletters, other news alerts that<br/>
                            you may have subscribed to and webinars, we ask<br/> 
                            for your name, phone number, designation, email<br/> 
                            address and the company you represent.Please<br/> 
                            note that we may also be collecting any information<br/> 
                            you may have mentioned in any communication/<br/>
                            email you send to us after registering.
                        </p>
                        <p>
                            By sharing your information on registration with<br/>
                                THAAYAKAM Careers, we obtain your name and<br/> 
                                email id to contact you as an Employment<br/>
                            candidate and consider for job opportunities<br/>
                            within THAAYAKAM.
                        </p>
                        <p>
                            Essential cookies are automatically placed on your<br/>
                            computer or device when you access our website or<br/> 
                            take specific actions on our website. You consent to<br/>
                            us placing non-essential cookies on your computer<br/>
                            or device by continuing to use our website with your<br/>
                            browser settings set to accept cookies.
                        </p>
                    </div>

                    <div id="tab-desk-privacy-txt-container">
                        <p>
                            By entering information to Contact Us, we collect your name, email address, country of residence,<br/> 
                            phone number, company you represent and details of the inquiry to enable us to respond to a<br/> 
                            general/business inquiry made by you on behalf of your company.
                        </p>
            
                        <p>
                            You provide us with information during registrations.To send you our newsletters, other news alerts<br/> 
                            that you may have subscribed to and webinars, we ask for your name, phone number, designation,<br/>  
                            email address and the company you represent. Please note that we may also be collecting any<br/>  
                            information you may have mentioned in any communication email you send to us after registering.
                        </p>
                        <p>
                            By sharing your information on registration with THAAYAKAM Careers, we obtain your name and email<br/>
                            id to contact you as an Employment candidate and consider for job opportunities within<br/>
                                THAAYAKAM.
                        </p>
                        <p>
                        Essential cookies are automatically placed on your computer or device when you access our website<br/>  or
                        take specific actions on our website. Your consent to us placing non-essential cookies on your<br/>
                        computer or device by continuing to use our website with your browser settings set to accept cookies.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}