import React from 'react';
import nock_img from '../images/knock/undraw_real_time_collaboration_c62i.svg';
export default class Knock extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          name: '',
          email:'',
          company:'',
          desYourIdea:'',
          formErrors: {name:'',email: '',company:'',Idea:''},
          nameValid: false,
          emailValid: false,
          companyValid: false,
          desYourIdeaValid: false,
          formValid: false
          };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateField = this.validateField.bind(this);
        this.validateForm = this.validateForm.bind(this);
      }

    handleChange(e) { 
        const name = e.target.name;
        const value = e.target.value;
        //this.setState({[val]:e.target.value}); 
        this.setState({[name]: value},() => { this.validateField(name, value) });
       }
       validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameValid = this.state.nameValid;
        let emailValid = this.state.emailValid;
        let companyValid = this.state.companyValid;
        let desYourIdeaValid = this.state.desYourIdeaValid;
      
        switch(fieldName) {
          case 'name':
            nameValid = value.length >= 1;
            fieldValidationErrors.name = nameValid ? '': 'This field is required';
            break;
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : ' is invalid';
            break;
          case 'company':
              companyValid = value.length >= 1;
              fieldValidationErrors.company = companyValid ? '': 'This field is required';
              break;
          case 'desYourIdea':
            desYourIdeaValid = value.length >= 1;
            fieldValidationErrors.Idea = desYourIdeaValid ? '': 'This field is required';
            break;
          default:
            break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        nameValid: nameValid,
                        emailValid: emailValid,
                        companyValid: companyValid,
                        desYourIdeaValid: desYourIdeaValid,
                      }, this.validateForm);
      }
      validateForm() {
        this.setState({formValid: this.state.nameValid && this.state.emailValid && this.state.companyValid && this.state.desYourIdeaValid});
        console.log('i am validartion',this.state.formValid);
      }
    handleSubmit(event) {
      document.getElementById('knock-popup-container').style.display='none';
      document.getElementById('subs-txt-container').style.display='block';
      document.getElementById('subs-popup').style.display='block';
      document.getElementById('loader').style.display='block';
      document.getElementById('contact-txt').style.display='none';
      document.getElementById('newsletter-txt').style.display='none';
      event.preventDefault();
      fetch('http://localhost:3001/store', {
          method: 'POST',
          body: JSON.stringify(this.state),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          
        })
           .then(response => response.json())
           .then((data) => {
              console.log('got');
                // document.getElementById('subs-popup').style.display='block';
                document.getElementById('subs-txt-container').style.display='block';
                document.getElementById('loader').style.display='none';
                document.getElementById('contact-txt').style.display='block';
                document.getElementById('newsletter-txt').style.display='none';
  
           
           })
           .catch((err) => {
            console.log('i am error');
            console.log(err.message);
          
           });
        
    }
 
    render(){
        return(
            <div className="knock-popup-container" id="knock-popup-container" >
            <div className="knock-popup-left-side">
                <img src={nock_img} alt=""/>
                <p>Hey 👋, We are looking forward<br/>
                to hear your message! We are<br/> 
                here to assist you to power-up<br/> 
                your journey 😀. Fill the<br/>
                following form & We will touch<br/>
                you back soon 🤟 !</p>
                <div>
                <a href="https://wa.me/message/DWHRSHNA7ZJKC1" target='_blank' rel="noreferrer" >To WhatsApp</a>
                </div>
            </div>
            <div  className="knock-popup-right-side">
                <h3>High Five 😎!</h3>
                <p>Fill the following form & we will touch you back soon.</p>
                <form id="knock-contact-form" onSubmit={this.handleSubmit}>
                    <div><input type="text" id="name" name="name" placeholder="Name" value={this.state.name} onChange={this.handleChange}/></div>
                    <div><input type="email" name="email" id="email" placeholder="Email" value={this.state.email} onChange={this.handleChange}/></div>
                    <div><input type="text" id="company" name="company" placeholder="Company" value={this.state.company} onChange={this.handleChange}/></div>
                    <div><textarea  id="des-your-idea" name="desYourIdea" placeholder="Describe your Idea" value={this.state.desYourIdea} onChange={this.handleChange}></textarea></div>
                    <div><input disabled={!this.state.formValid} type="submit"  value="Send Us"/></div>
                </form> 
            </div>
        </div>
        );
    }
}