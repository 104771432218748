

export function off() {
  document.getElementById("overlay").style.display = "none";
  // document.getElementById('mob-tab-knock-popup').style.display='none';
  document.getElementById('knock-popup-container').style.display='none';
  document.getElementById("hamburger").style.zIndex = 1;
  document.getElementById("hamburger").style.opacity= 1;
  document.getElementById('popup-navbar-content').style.display='block';
  document.getElementById('popup-navbar-content').style.right='-70%';
}
export function mobTabKnockPopup(){
  document.getElementById('mob-tab-knock-popup').style.display='block';
  document.getElementById('popup-navbar-content').style.display='none';
  document.getElementById("overlay").style.display = "block";
}
export function popup(){
  document.getElementById("hamburger").classList.add("fade-out");
  document.getElementById("hamburger").classList.remove("fade-in");
  document.getElementById("popup-close-btn").classList.add("fade-in");
  document.getElementById('popup-navbar-content').style.right=0;
  document.getElementById("hamburger").style.opacity= 0;
  document.getElementById("hamburger").style.zIndex = -1;
  document.getElementById("popup-close-btn").style.opacity = 1;
}
export function closePopup(){
  //document.getElementById('popup-navbar-content').style.display='block';
  document.getElementById('popup-navbar-content').style.right='-70%';
  document.getElementById("hamburger").classList.add("fade-in");
  document.getElementById("popup-close-btn").classList.remove("fade-in");
  document.getElementById("hamburger").style.zIndex = 1;
  document.getElementById("hamburger").style.opacity= 1;
  document.getElementById("popup-close-btn").classList.add("fade-out");
}
export function knockPopup(){
  document.getElementById('knock-popup-container').style.display='flex';
  document.getElementById("overlay").style.display = "block";
}
export function closeKnockPopup(){
  document.getElementById('knock-popup-container').style.display='none';
  document.getElementById("overlay").style.display = "none";
  document.getElementById('mob-tab-knock-popup').style.display='none';
}

export function logoClick(){
  sessionStorage.setItem("visited",1);
}
export default function createVisitedLink(){
  console.log('kopi');
  return 'hi da';
}
export function kopi(){
  console.log('hi da kopi woow');
}
export function loadVisitedLink(id_txt,cls,id){
      const collection = document.getElementsByClassName(cls);
      for (let i = 0; i < collection.length; i++) {
        collection[i].style.color= "black";
        }
        document.getElementById(id_txt+id).style.color='#15CEFF';
        closePopup()
        window.scrollTo(0,0);
  }
  


 let pro1=1;
 let pro2=0;
 let pro3=0;
 let pro4=0;
 let end=0;
 let pro2_loc=267;
 let pro3_loc=520;
 let pro4_loc=780;


 export function processScroll() {
  var x=document.getElementById('process-slider').scrollLeft;
  console.log(x);
  const collection = document.getElementsByClassName("process-description");

  if(x){
    if(pro1 && x<pro2_loc){
      if(pro1){
        setTimeout(function() {
          document.getElementById('process-slider').scrollLeft=pro2_loc;
          pro1=0;
          pro2=1;
          for (let i = 0; i < collection.length; i++) {
            collection[i].style.display = "none";
            }
          document.getElementById('process-des-2').style.display='block';
      }, 100);
        
      }
    }
    if(pro2 && x>pro2_loc){
      setTimeout(function(){
        document.getElementById('process-slider').scrollLeft=pro3_loc;
        console.log('process-3');
        for (let i = 0; i < collection.length; i++) {
          collection[i].style.display = "none";
          }
        document.getElementById('process-des-3').style.display='block';
        pro3=1;
        pro2=0;
      },100);
     
    }
    if(pro3 && x>pro3_loc){
      pro3=0;
      console.log('before check it');
      setTimeout(function(){
        document.getElementById('process-slider').scrollLeft= pro4_loc;
        for (let i = 0; i < collection.length; i++) {
          collection[i].style.display = "none";
          }
        document.getElementById('process-des-4').style.display='block';
        // pro3=0
        pro4=1;
        x=document.getElementById('process-slider').scrollLeft;
        console.log('weee111',x);
      },100);
      x=document.getElementById('process-slider').scrollLeft;
     console.log('weee',x);
      
    }
    if(pro4 && x===pro4_loc){
      console.log('last checku');
      end=1;
      
    }
   if(end && x<pro4_loc){
    setTimeout(function(){
      pro1=1;
    },1000);
    document.getElementById('process-slider').scrollLeft= 0;
    console.log('end to start');
    end=0;
    
   }
  
  }
 }



export function showDescription(obj,id,id2,id3="",id4){
    const collection = document.getElementsByClassName("process-description");
for (let i = 0; i < collection.length; i++) {
  collection[i].style.display = "none";
}
    document.getElementById(id4).style.display='block'
    if(id3) document.getElementById(id3).style.opacity=1;
    
    document.getElementById(id2).style.opacity=1;
    document.getElementById(id).style.opacity=1;
    document.getElementById(obj).style.opacity=1;

  

}

