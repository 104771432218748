import { Link } from 'react-router-dom';
import {loadVisitedLink} from '../script';
export default function OurProject(props){
  let service_btn;
  if(props.name!=='services'){
  service_btn=<Link id='pro-service-btn'  to='/services' onClick={(e)=>loadVisitedLink('nav-link-','nav-link',3)}>SERVICES</Link>;
  }
    return(
        <div id="project-details-container">
<div id="project-count-container">
  <div><span>6+</span><span>Successful Projects</span></div>
  <div><span>4+</span><span>Satisfied Clients</span></div>
  <div><span>4+</span><span>Countries we Reached</span></div>
</div>
<div>
  <p>Are you searching for a <b>finest team</b> ⚡️ to take your idea to the ever active market 🚀?<br/> 
    You are in a <b>right place</b> to get gear-up 💪!</p>
</div>
<div id="project-btn-container">
  {service_btn} 
  <Link id="pro-quoto-btn" to="/contact" onClick={(e)=>loadVisitedLink('nav-link-','nav-link',5)}>TO GET QUOTE</Link>
</div>
</div>
    );
}