import logo1 from '../logo-1.svg';
import logo2 from '../logo-2.svg';
import close_img from '../images/Other Icons/close menu.svg';
import hamburger from '../images/Other Icons/Sandwich Menu 2.svg';
import { Link } from 'react-router-dom';
import Knock from './Knock';
import MobTabKnock from './MobTabKnock';
import SubsPopup from './SubsPopup';
import {loadVisitedLink,knockPopup,closeKnockPopup,mobTabKnockPopup,popup,closePopup,logoClick} from '../script';

function Navigation(){
    const overlay={
        display:'none',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(0, 0, 0, 0.7)',
        transition: 'opacity 500ms',
        zIndex:1
      }
    return (
        <div id="nav-bar-container">
             {/* <div id="overlay" onClick={off}></div> */}
             <div id="subs-popup" style={{display:'none'}}><SubsPopup /></div>
            <nav className="nav-bar">
                <Link id="logo-link-container" to="/" onClick={logoClick}>
                    <div className="logo-container">
                        <img className="logo logo-1" src={logo1} alt="" width="50px"/>
                        <img className="logo" src={logo2} alt="" width="150px"/>
                    </div>
                </Link>
                <div className="hamburger-container">
                    <img id="popup-close-btn" onClick={closePopup} src={close_img} alt=""/>
                    <img id="hamburger" onClick={popup} src={hamburger} alt=""/>  
                </div>
                <ul>
                    <li><Link id="nav-link-1" className="nav-link" to="/" style={{color:'#15CEFF'}} onClick={(e)=>loadVisitedLink('nav-link-','nav-link',1)}>HOME</Link></li>
                    <li><Link id="nav-link-2" className="nav-link" to="we-are" onClick={(e)=>loadVisitedLink('nav-link-','nav-link',2)}>WE ARE</Link></li>
                    <li><Link id="nav-link-3" className="nav-link" to="services" onClick={(e)=>loadVisitedLink('nav-link-','nav-link',3)}>SERVICES</Link></li>
                    <li><Link id="nav-link-4" className="nav-link" to='careers' onClick={(e)=>loadVisitedLink('nav-link-','nav-link',4)}>CAREERS</Link></li>
                    <li><Link id="nav-link-5" className="nav-link" to='contact' onClick={(e)=>loadVisitedLink('nav-link-','nav-link',5)}>CONTACT</Link></li>
                </ul>
                <div className="popup-navbar-content" id="popup-navbar-content">
                    <ul>
                        <li><Link id="tab-mob-nav-link-1" className="tab-mob-nav-link" style={{color:'#15CEFF'}} to="/" onClick={(e)=>loadVisitedLink('tab-mob-nav-link-','tab-mob-nav-link',1)}>HOME</Link></li>
                        <li><Link id="tab-mob-nav-link-2" className="tab-mob-nav-link" to="we-are" onClick={(e)=>loadVisitedLink('tab-mob-nav-link-','tab-mob-nav-link',2)}>WE ARE</Link></li>
                        <li><Link id="tab-mob-nav-link-3" className="tab-mob-nav-link"  to="services" onClick={(e)=>loadVisitedLink('tab-mob-nav-link-','tab-mob-nav-link',3)}>SERVICES</Link></li>
                        <li><Link id="tab-mob-nav-link-4" className="tab-mob-nav-link" to="careers" onClick={(e)=>loadVisitedLink('tab-mob-nav-link-','tab-mob-nav-link',4)}>CAREER</Link></li>
                        <li><Link id="tab-mob-nav-link-5" className="tab-mob-nav-link" to="contact" onClick={(e)=>loadVisitedLink('tab-mob-nav-link-','tab-mob-nav-link',5)}>CONTACT</Link></li>
                    </ul>
                        <div className="knock" onClick={mobTabKnockPopup}>
                            <span className="knock-content">Knock</span><span>✊</span>
                        </div>
                        <footer className="popup-social-footer">
                        <div className="social-footer-wrapper">
                            <a href="https://www.facebook.com/thaayakamltd" className="social-link" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.614" height="24.612" viewBox="0 0 24.614 24.612">
                                <g id="facebook-circular-logo" transform="translate(0 0)">
                                <circle id="Ellipse_89" data-name="Ellipse 89" cx="12.305" cy="12.305" r="12.305" transform="translate(0 0)" fill="#fff"/>
                                <path id="Path_291" data-name="Path 291" d="M12.307.349a12.355,12.355,0,0,0-2.053,24.529V15.285H7.286V11.832h2.969V9.287c0-2.954,1.8-4.563,4.421-4.563a24.4,24.4,0,0,1,2.651.135V7.946h-1.82c-1.427,0-1.7.681-1.7,1.68v2.2h3.4l-.444,3.452H13.8v9.678A12.355,12.355,0,0,0,12.307.349Z" transform="translate(0 -0.349)" fill="#15ceff"/>
                                </g>
                            </svg>
                            </a>
                            <a href="https://www.instagram.com/thaayakamltd" className="social-link" target="_blank" rel="noreferrer">
                            <svg id="instagram" xmlns="http://www.w3.org/2000/svg" width="24.612" height="24.612" viewBox="0 0 24.612 24.612">
                                <circle id="Ellipse_88" data-name="Ellipse 88" cx="12.305" cy="12.305" r="12.305" fill="#fff"/>
                                <path id="Path_292" data-name="Path 292" d="M211.514,209.257A2.257,2.257,0,1,1,209.257,207,2.257,2.257,0,0,1,211.514,209.257Zm0,0" transform="translate(-196.951 -196.951)" fill="#15ceff"/>
                                <path id="Path_293" data-name="Path 293" d="M146.314,137.046a2.242,2.242,0,0,0-1.285-1.285,3.746,3.746,0,0,0-1.257-.233c-.714-.033-.928-.04-2.736-.04s-2.022.007-2.736.039a3.748,3.748,0,0,0-1.257.233,2.243,2.243,0,0,0-1.285,1.285,3.748,3.748,0,0,0-.233,1.257c-.033.714-.04.928-.04,2.736s.007,2.022.04,2.736a3.746,3.746,0,0,0,.233,1.257,2.242,2.242,0,0,0,1.285,1.285,3.742,3.742,0,0,0,1.257.233c.714.033.928.039,2.736.039s2.022-.007,2.736-.039a3.742,3.742,0,0,0,1.257-.233,2.242,2.242,0,0,0,1.285-1.285,3.75,3.75,0,0,0,.233-1.257c.033-.714.039-.928.039-2.736s-.007-2.022-.039-2.736A3.742,3.742,0,0,0,146.314,137.046Zm-5.278,7.47a3.477,3.477,0,1,1,3.477-3.477A3.477,3.477,0,0,1,141.036,144.517Zm3.614-6.279a.813.813,0,1,1,.813-.813A.813.813,0,0,1,144.65,138.238Zm0,0" transform="translate(-128.73 -128.734)" fill="#15ceff"/>
                                <path id="Path_294" data-name="Path 294" d="M12.306,0A12.306,12.306,0,1,0,24.612,12.306,12.307,12.307,0,0,0,12.306,0ZM19.33,15.219A5.184,5.184,0,0,1,19,16.935,3.613,3.613,0,0,1,16.935,19a5.188,5.188,0,0,1-1.715.328c-.754.034-.994.043-2.913.043s-2.16-.008-2.914-.043A5.188,5.188,0,0,1,7.678,19a3.613,3.613,0,0,1-2.067-2.067,5.183,5.183,0,0,1-.328-1.715c-.035-.754-.043-.994-.043-2.914s.008-2.16.043-2.913a5.185,5.185,0,0,1,.328-1.715A3.616,3.616,0,0,1,7.677,5.611a5.189,5.189,0,0,1,1.715-.328c.754-.034.994-.043,2.913-.043s2.16.008,2.913.043a5.19,5.19,0,0,1,1.715.328A3.614,3.614,0,0,1,19,7.677a5.184,5.184,0,0,1,.329,1.715c.034.754.042.994.042,2.913S19.364,14.466,19.33,15.219Zm0,0" fill="#15ceff"/>
                            </svg>                  
                            </a>
                            <a href="https://www.facebook.com/thaayakamltd" className="social-link" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.612" height="24.612" viewBox="0 0 24.612 24.612">
                                <g id="Linked_In" data-name="Linked In" transform="translate(-624 -722)">
                                <circle id="Ellipse_90" data-name="Ellipse 90" cx="12.305" cy="12.305" r="12.305" transform="translate(624 722)" fill="#fff"/>
                                <path id="linkedin" d="M12.306,0A12.306,12.306,0,1,0,24.612,12.306,12.307,12.307,0,0,0,12.306,0ZM8.73,18.6h-3V9.586h3ZM7.232,8.355h-.02A1.562,1.562,0,1,1,7.251,5.24a1.563,1.563,0,1,1-.02,3.116ZM19.537,18.6h-3V13.779c0-1.212-.434-2.039-1.518-2.039a1.641,1.641,0,0,0-1.538,1.1,2.052,2.052,0,0,0-.1.731V18.6h-3s.039-8.171,0-9.017h3v1.277a2.975,2.975,0,0,1,2.7-1.488c1.972,0,3.45,1.289,3.45,4.058Zm0,0" transform="translate(624 722)" fill="#15ceff"/>
                                </g>
                            </svg>
                                            
                            </a>
                            <a href="https://twitter.com/ThaayakamLTD" className="social-link" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.61" height="24.61" viewBox="0 0 24.61 24.61">
                                <g id="Twitter" transform="translate(-720 -722)">
                                <circle id="Ellipse_91" data-name="Ellipse 91" cx="12.305" cy="12.305" r="12.305" transform="translate(720 722)" fill="#fff"/>
                                <path id="circletwitter" d="M12.305,24.61a12.071,12.071,0,0,1-4.783-.973A12.04,12.04,0,0,1,.973,17.088a12.235,12.235,0,0,1,0-9.565A12.04,12.04,0,0,1,7.522.973a12.235,12.235,0,0,1,9.565,0,12.04,12.04,0,0,1,6.549,6.549,12.235,12.235,0,0,1,0,9.565,12.04,12.04,0,0,1-6.549,6.549,12.071,12.071,0,0,1-4.783.973ZM19.539,6.153a4.443,4.443,0,0,0-.433.265l-.469.312-.444.241-.5.168A2.832,2.832,0,0,0,15.5,6.153q-2.812,0-2.812,2.355V9.926A7.6,7.6,0,0,1,6.753,7.09a1.941,1.941,0,0,0-.6,1.37,2.739,2.739,0,0,0,1.178,2.4q-.144,0-.409.024a1.822,1.822,0,0,1-.42,0,1.2,1.2,0,0,1-.349-.12,2.842,2.842,0,0,0,.589,1.838,2.714,2.714,0,0,0,1.622.949.828.828,0,0,1-.673.288,1.089,1.089,0,0,1-.673-.216,1.6,1.6,0,0,0,.9,1.454,4.192,4.192,0,0,0,2.031.541,2.606,2.606,0,0,1-1.262.973,4.791,4.791,0,0,1-1.766.324A3.615,3.615,0,0,1,6,16.751q-.589-.168-.613-.168a3.141,3.141,0,0,0,1.574,1.322,7.16,7.16,0,0,0,3.016.552,7.929,7.929,0,0,0,3-.565,7.473,7.473,0,0,0,2.379-1.5,9.868,9.868,0,0,0,1.695-2.139,10,10,0,0,0,1.058-2.488,9.716,9.716,0,0,0,.348-2.536q0-.048.288-.2a7.755,7.755,0,0,0,.673-.42A2.126,2.126,0,0,0,20,8.051q-1.3,0-1.73.048a3.229,3.229,0,0,0,1.274-1.947Z" transform="translate(720 722)" fill="#15ceff"/>
                                </g>
                            </svg>
                            
                            </a>
                            <a href="https://wa.me/message/DWHRSHNA7ZJKC1" className="social-link" target="_blank" rel="noreferrer">
                            <svg id="whatsapp" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                                <circle id="Ellipse_92" data-name="Ellipse 92" cx="12.305" cy="12.305" r="12.305" transform="translate(0 0)" fill="#fff"/>
                                <path id="Path_295" data-name="Path 295" d="M16.5,8.68A5.45,5.45,0,0,0,8,15.41l.13.21-.55,2,2.06-.54.2.12a5.48,5.48,0,0,0,2.78.76h0a5.46,5.46,0,0,0,5.46-5.44A5.39,5.39,0,0,0,16.5,8.68Zm-.66,6.16a1.67,1.67,0,0,1-1.11.79,2.16,2.16,0,0,1-1-.07c-.23-.08-.54-.18-.93-.34A7.33,7.33,0,0,1,10,12.75a3.17,3.17,0,0,1-.67-1.69A1.84,1.84,0,0,1,9.86,9.7a.62.62,0,0,1,.44-.2h.31c.1,0,.23,0,.37.28s.47,1.13.51,1.21a.3.3,0,0,1,0,.29,1.91,1.91,0,0,1-.16.27l-.25.29c-.08.08-.17.17-.07.33a5.07,5.07,0,0,0,.91,1.14,4.35,4.35,0,0,0,1.33.82c.16.07.26.07.35,0a5.474,5.474,0,0,0,.52-.64c.11-.16.22-.14.37-.08s1,.45,1.12.53h0c.16.07.27.11.31.19a1.4,1.4,0,0,1-.08.71Z" transform="translate(-0.149 -0.036)" fill="#15ceff"/>
                                <path id="Path_296" data-name="Path 296" d="M12.5,0A12.5,12.5,0,1,0,25,12.5,12.5,12.5,0,0,0,12.5,0Zm.14,19.09h0a6.66,6.66,0,0,1-3.14-.8L6,19.2l.93-3.39A6.55,6.55,0,0,1,12.62,6a6.65,6.65,0,0,1,4.66,1.9h0a6.54,6.54,0,0,1-4.64,11.17Z" transform="translate(0)" fill="#15ceff"/>
                            </svg>
                            
                            </a>
                        </div>
                    </footer>
                        
                </div>
                <div className="knock-container" onClick={knockPopup}>
                    <div id="desk-knock">
                        <span className="knock-content">Knock</span><span>✊</span>
                    </div>
                </div>
                <Knock/>
                <MobTabKnock />
                <div id="overlay" style={overlay} onClick={closeKnockPopup}></div>
               
            </nav>
        </div>
    );
}

export default Navigation;
