import LandingPage from "../components/LandingPage";
import OurServices from "../components/OurServcies";
import OurProcess from "../components/OurProcess";
import OurProject from "../components/OurProject";
export default function HomePage(){
    return(
        <div>
            <LandingPage/>
            <OurServices/>
            <OurProcess/>
            <OurProject/>
        </div>
    );
}