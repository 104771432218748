import AWS from "../images/Technology Stack/AWS.svg";
import GCP from "../images/Technology Stack/GCP-icon.svg"; 
import Azure from "../images/Technology Stack/Azure.svg";
import oracle from "../images/Technology Stack/oracle-logo.svg"; 
import DigitalOcean from "../images/Technology Stack/DigitalOcean-icon.svg"; 
import Terraform  from "../images/Technology Stack/Terraform-icon.svg"; 
import python from "../images/Technology Stack/python.svg"; 
import tensorflow from "../images/Technology Stack/tensorflow.svg"; 
import Laravel from "../images/Technology Stack/Laravel.svg"; 
import springBoot from "../images/Technology Stack/springio-icon.svg"; 
import nodejs from "../images/Technology Stack/nodejs.svg"; 
import django from "../images/Technology Stack/django.svg"; 
import ASPNET from "../images/Technology Stack/ASP .NET.svg"; 
import java from "../images/Technology Stack/java.svg"; 
import JS from "../images/Technology Stack/JS.svg"; 
import angular from "../images/Technology Stack/angular.svg"; 
import flutter from "../images/Technology Stack/flutter.svg"; 
import reactjs from "../images/Technology Stack/reactjs.svg"; 
import shopify from "../images/Technology Stack/shopify.svg"; 
import wordpress from "../images/Technology Stack/wordpress.svg"; 
import AVR from "../images/Technology Stack/AVR.svg";
import selenium from "../images/Technology Stack/selenium.svg"; 
import blender from "../images/Technology Stack/blender.svg"; 
import adobe from "../images/Technology Stack/adobe.svg";

export default function Services(){
    return(
        <div>
            <div className="services-main-container">
                <h2 className="headline-txt">What we do!</h2>
                <p id="services-tab-desk-txt">
                    Our team 😎 with extensive knowledge and innovative thoughts is here<br/> 
                    to deliver innovative and top-performing solutions tied with<br/>
                    robust, trust and intelligence to deploy your performance into a new era!
                </p>
                <p id="services-mob-txt">
                    Our team 😎 with extensive knowledge and<br/>
                    innovative thoughts is here to deliver<br/>
                    innovative and top-performing solutions<br/>
                    tied with robust, trust and intelligence to<br/>
                    deploy your performance into a new era!
                </p>
                <div className="services-container">
            <div className="service">
                <h3>Cloud Consultation</h3>
                <p>
                    To deploy your business's digital
                    platform into the cloud, We deliver top
                    performing and highly secured
                    solutions at optimised costs. Our
                    engineers qualified in world-leading 
                    cloud providers AWS,GCP and Azure 
                    will work with:
                </p>
                <div>
                    <p>Cloud Design and Build</p>
                    <p>Data Migration</p>
                    <p>Cloud Security</p>
                </div>
            </div>
            <div className="service">
                <h3>Mobile App</h3>
                <p>
                To let your customer access your products and follow your fashions at their fingertips, We deliver mobile applications through which you forecast your outcomes to the world. Our team will be working on various technologies to assist you throughout:
                </p>
                <div>
                    <p>Android Application</p>
                    <p>iOS Application</p>
                    <p>Maintenance</p>
                </div>
            </div>
            <div className="service">
                <h3>Web App</h3>
                <p>Showcase your products and services to the world through dazzling websites with a wide range of features. We empower our clients with adaptive websites using a wide range of tools to simplify the complex business applications through :
                </p>
                <div>
                    <p>Backend & Frontend Dev</p>
                    <p>Customized APIs </p>
                    <p>Maintenance</p>
                </div>
            </div>
            <div className="service">
                <h3>Managed IT</h3>
                <p>To maintain your business's continuity and growth by enforcing effective IT services By establishing comprehensive and secure Partnership with us, our IT experts reduce your burden with technical administration and operations throughout :  
                </p>
                <div>
                    <p>Managed Infrastructure</p>
                    <p>Managed Application</p>
                    <p>Managed Security</p>
                </div>
            </div>
            <div className="service">
                <h3>Brand Design</h3>
                <p>
                To reflect your brand identity into an ever-changing competitive marketand maximizing customer interactions and lasting impressions on your products, Our team will work for you in a complete creative, and passionate manner on:
                </p>
                <div>
                    <p>Identity Design</p>
                    <p>UI / UX </p>
                    <p>Product Design</p>
                </div>
            </div>
            <div className="service">
                <h3>Internet Of Things</h3>
                <p>Bridging you and your station to stay connected, measured, monitored, and controlled remotely via the internet. Our team will work for enforcing the characteristics of the internet to build Full-Cycle solutions for:
                </p>
                <div>
                    <p>Agriculture IoT</p>
                    <p>Smart Home & Utilities</p>
                    <p>Medical IoT</p>
                </div>
            </div>
                </div>
            </div>

            <div className="tech-stack-container">
<h2>Tech Stack</h2>
<div className="tech-stack-wrapper">
  <div><img src={AWS} alt=""/><span>AWS</span></div>
  <div><img src={GCP} alt=""/><span>GCP</span></div>
  <div><img src={Azure} alt=""/><span>Azure</span></div>
  <div><img src={oracle} alt=""/><span>Oracle</span></div>
  <div><img src={DigitalOcean} alt=""/><span>DigitalOcean</span></div>
  <div><img src={Terraform} alt=""/><span>Terraform</span></div>
  <div><img src={python} alt=""/><span>Python</span></div>
  <div><img src={tensorflow} alt=""/><span>TensorFlow</span></div>
  <div><img src={Laravel} alt=""/><span>Laravel</span></div>
  <div><img src={springBoot} alt=""/><span>Spring Boot</span></div>
  <div><img src={nodejs} alt=""/><span>node JS</span></div>
  <div><img src={django} alt=""/><span>django</span></div>
  <div><img src={ASPNET} alt=""/><span>ASP .NET</span></div>
  <div><img src={java} alt=""/><span>Java</span></div>
  <div><img src={JS} alt=""/><span>Java Script</span></div>
  <div><img src={angular} alt=""/><span>Angular</span></div>
  <div><img src={flutter} alt=""/><span>Flutter</span></div>
  <div><img src={reactjs} alt=""/><span>React Js</span></div>
  <div><img src={shopify} alt=""/><span>Shopify</span></div>
  <div><img src={wordpress} alt=""/><span>Word Press</span></div>
  <div><img src={AVR} alt=""/><span>AVR</span></div>
  <div><img src={selenium} alt=""/><span>Selenium</span></div>
  <div><img src={blender} alt=""/><span>Blender</span></div>
  <div><img src={adobe} alt=""/><span>Adobe</span></div>
</div>
<p>+ more high-end and latest technologies and tools 👍.</p>
            </div>
        </div>
    );
}