import Services from "../components/Services";
import OurProcess from "../components/OurProcess";
import OurProject from "../components/OurProject";
export default function ServicesPage(){
    return(
        <div>
            <Services/>
            <OurProcess/>
            <OurProject name="services" />
        </div>
    );
}