import img_1 from '../images/services/Cloud Consultation.svg';
import img_2 from '../images/services/Mobile App.svg';
import img_3 from '../images/services/Web App.svg';
import img_4 from '../images/services/Brand Design.svg';
import img_5 from '../images/services/Managed IT.svg';
import img_6 from '../images/services/IoT.svg';
export default function OurServices(){
    return(
        <div className="landing-services-container">
    <h2 className="headline-txt">What we do!</h2>
    <p id="mob-body-txt" className="body-txt">We deliver 🙌 creatively led and strategically <br/>
       driven innovative solutions💡 tied with <br/>
        robust, trust and intelligence to <br/> 
        deploy your dream toa new era!</p>
    <p className="tab-desk-body-txt">We deliver 🙌 creatively led and strategically driven innovative solutions💡<br/>
      tied with robust, trust and intelligence to deploy your dream to a new era!</p>
    <div className="services-icons-container">
        <div className="services-icon"><img src={img_1} alt="" /><span>Cloud Consultation</span></div>
        <div className="services-icon"><img src={img_2} alt="" /><span>Mobile App</span></div>
        <div className="services-icon"><img src={img_3} alt="" /><span>Web App</span></div>
        <div className="services-icon"><img src={img_4} alt="" /><span>Brand Design</span></div>
        <div className="services-icon"><img src={img_5} alt="" /><span>Managed IT</span></div>
        <div className="services-icon"><img src={img_6} alt="" /><span>Internet Of Things</span></div> 
    </div>
</div>
    );
}