import {processScroll,showDescription} from '../script'
export default function OurProcess(){
    return(
        <div className="process-container">
    <h2 className="headline-txt">How we do!</h2>
    <div className="process-wrapper">
        <div className="our-process-mob-view process-slider" id="process-slider"  onScroll={processScroll}>
          <div style={{flexBasis:'90px',flexShrink:'0'}}></div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="212.663" height="238.967" viewBox="0 0 212.663 238.967">
              <defs>
                <filter id="Polygon_mob_1" x="4.531" y="1.943" width="204.052" height="235.082" filterUnits="userSpaceOnUse">
                  <feOffset input="SourceAlpha"/>
                  <feGaussianBlur stdDeviation="5" result="blur"/>
                  <feFlood floodOpacity="0.161" result="color"/>
                  <feComposite operator="out" in="SourceGraphic" in2="blur"/>
                  <feComposite operator="in" in="color"/>
                  <feComposite operator="in" in2="SourceGraphic"/>
                </filter>
              </defs>
              <g id="Group_500" data-name="Group 500" transform="translate(-188 -1599.865)">
                <g id="Group_388" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-2" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 808.05, 153.37)" filter="url(#Polygon_mob_1)">
                      <path id="Polygon_1-3" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(208.58 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#15ceff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_1" data-name="#1" transform="translate(294 1652)" fill="#15ceff" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-12.69" y="0">#1</tspan></text>
                <text id="Scoping_Estimation" data-name="Scoping
            &amp;
            Estimation" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-35.872" y="0">Scoping</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-48" y="50">Estimation</tspan></text>
              </g>
            </svg>
            </div>

            <div className="process-dash-line">
              <svg xmlns="http://www.w3.org/2000/svg" width="46.148" height="2" viewBox="0 0 46.148 2">
                <path id="Dotted_Line_Mobile" data-name="Dotted Line Mobile" d="M0,0H44.148" transform="translate(45.148 1) rotate(180)" fill="none" stroke="#ff9d00" strokeLinecap="round" strokeWidth="2" strokeDasharray="3 10"/>
              </svg>              
            </div>

           <div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="212.663" height="238.967" viewBox="0 0 212.663 238.967">
              <defs>
                <filter id="Polygon_mob_2" x="4.53" y="1.943" width="204.052" height="235.082" filterUnits="userSpaceOnUse">
                  <feOffset input="SourceAlpha"/>
                  <feGaussianBlur stdDeviation="5" result="blur"/>
                  <feFlood floodOpacity="0.161" result="color"/>
                  <feComposite operator="out" in="SourceGraphic" in2="blur"/>
                  <feComposite operator="in" in="color"/>
                  <feComposite operator="in" in2="SourceGraphic"/>
                </filter>
              </defs>
              <g id="Group_501" data-name="Group 501" transform="translate(-188 -1599.865)">
                <g id="Group_388" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-2" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 808.05, 153.37)" filter="url(#Polygon_mob_2)">
                      <path id="Polygon_1-3" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(208.58 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#41d692" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_2" data-name="#2" transform="translate(294 1652)" fill="#41d692" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-14.81" y="0">#2</tspan></text>
                <text id="Design_Development" data-name="Design
            &amp;
            Development" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-30.392" y="0">Design</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-59.608" y="50">Development</tspan></text>
              </g>
            </svg>
            
           </div>

           <div className="process-dash-line">
            <svg xmlns="http://www.w3.org/2000/svg" width="46.148" height="2" viewBox="0 0 46.148 2">
              <path id="Dotted_Line_Mobile" data-name="Dotted Line Mobile" d="M0,0H44.148" transform="translate(45.148 1) rotate(180)" fill="none" stroke="#ff9d00" strokeLinecap="round" strokeWidth="2" strokeDasharray="3 10"/>
            </svg>              
          </div>

           <div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="212.663" height="238.967" viewBox="0 0 212.663 238.967">
              <defs>
                <filter id="Polygon_mob_3" x="4.53" y="1.943" width="204.052" height="235.082" filterUnits="userSpaceOnUse">
                  <feOffset input="SourceAlpha"/>
                  <feGaussianBlur stdDeviation="5" result="blur"/>
                  <feFlood floodOpacity="0.161" result="color"/>
                  <feComposite operator="out" in="SourceGraphic" in2="blur"/>
                  <feComposite operator="in" in="color"/>
                  <feComposite operator="in" in2="SourceGraphic"/>
                </filter>
              </defs>
              <g id="Group_391" data-name="Group 391" transform="translate(-188 -1599.865)">
                <g id="Group_388" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-2" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 808.05, 153.37)" filter="url(#Polygon_mob_3)">
                      <path id="Polygon_1-3" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(208.58 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#15ceff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_3" data-name="#3" transform="translate(294 1652)" fill="#15ceff" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-15.06" y="0">#3</tspan></text>
                <text id="Testing_Deployment" data-name="Testing
            &amp;
            Deployment" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-32.952" y="0">Testing</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-54.328" y="50">Deployment</tspan></text>
              </g>
            </svg>
            
           </div>
              <div className="process-dash-line">
              <svg xmlns="http://www.w3.org/2000/svg" width="46.148" height="2" viewBox="0 0 46.148 2">
                <path id="Dotted_Line_Mobile" data-name="Dotted Line Mobile" d="M0,0H44.148" transform="translate(45.148 1) rotate(180)" fill="none" stroke="#ff9d00" strokeLinecap="round" strokeWidth="2" strokeDasharray="3 10"/>
              </svg>              
            </div>
           <div>
            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="212.663" height="238.967" viewBox="0 0 212.663 238.967">
              <defs>
                <filter id="Polygon_mob_4" x="4.53" y="1.943" width="204.052" height="235.082" filterUnits="userSpaceOnUse">
                  <feOffset input="SourceAlpha"/>
                  <feGaussianBlur stdDeviation="5" result="blur"/>
                  <feFlood floodOpacity="0.161" result="color"/>
                  <feComposite operator="out" in="SourceGraphic" in2="blur"/>
                  <feComposite operator="in" in="color"/>
                  <feComposite operator="in" in2="SourceGraphic"/>
                </filter>
              </defs>
              <g id="Group_392" data-name="Group 392" transform="translate(-188 -1599.865)">
                <g id="Group_388" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-2" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 808.05, 153.37)" filter="url(#Polygon_mob_4)">
                      <path id="Polygon_1-3" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(208.58 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#41d692" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_4" data-name="#4" transform="translate(294 1652)" fill="#41d692" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-15.68" y="0">#4</tspan></text>
                <text id="Maintenance_Support" data-name="Maintenance
            &amp;
            Support" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-58.688" y="0">Maintenance</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-35.344" y="50">Support</tspan></text>
              </g>
            </svg>
            
           </div>
           <div style={{flexBasis:'90px',flexShrink:0}}></div>
        </div>
        <div className="our-process-tab-view">
          <svg id="our-process-method-tab" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="483.831" height="590.665" viewBox="0 0 483.831 590.665">
            <defs>
              <filter id="Polygon_tab_1" x="4.699" y="1.943" width="204.052" height="235.081" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="5" result="blur"/>
                <feFlood floodOpacity="0.161" result="color"/>
                <feComposite operator="out" in="SourceGraphic" in2="blur"/>
                <feComposite operator="in" in="color"/>
                <feComposite operator="in" in2="SourceGraphic"/>
              </filter>
              <filter id="Polygon_tab_1-2" x="275.699" y="1.943" width="204.052" height="235.081" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="5" result="blur-2"/>
                <feFlood floodOpacity="0.161" result="color-2"/>
                <feComposite operator="out" in="SourceGraphic" in2="blur-2"/>
                <feComposite operator="in" in="color-2"/>
                <feComposite operator="in" in2="SourceGraphic"/>
              </filter>
              <filter id="Polygon_tab_1-3" x="275.53" y="353.64" width="204.052" height="235.081" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="5" result="blur-3"/>
                <feFlood floodOpacity="0.161" result="color-3"/>
                <feComposite operator="out" in="SourceGraphic" in2="blur-3"/>
                <feComposite operator="in" in="color-3"/>
                <feComposite operator="in" in2="SourceGraphic"/>
              </filter>
              <filter id="Polygon_tab_1-4" x="4.53" y="353.64" width="204.052" height="235.081" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="5" result="blur-4"/>
                <feFlood floodOpacity="0.161" result="color-4"/>
                <feComposite operator="out" in="SourceGraphic" in2="blur-4"/>
                <feComposite operator="in" in="color-4"/>
                <feComposite operator="in" in2="SourceGraphic"/>
              </filter>
            </defs>
            <g id="Group_571" data-name="Group 571" transform="translate(-270 -1746.865)">
              <g id="tap-Group_389" data-name="Group 389" transform="translate(82.169 147)" onClick={(e)=>showDescription('tap-Group_389','tab_Ellipse_120','tab_Dot','jkj','process-des-1')}>
                <g id="Group_388" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-5" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 807.88, 153.36)" filter="url(#Polygon_tab_1)">
                      <path id="Polygon_1-6" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(208.75 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#15ceff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_1" data-name="#1" transform="translate(294 1652)" fill="#15ceff" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-12.69" y="0">#1</tspan></text>
                <text id="Scoping_Estimation" data-name="Scoping
          &amp;
          Estimation" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-35.872" y="0">Scoping</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-48" y="50">Estimation</tspan></text>
              </g>
              <g id="tab-Group_390" data-name="Group 390" transform="translate(353.169 147)" onClick={(e)=>showDescription('tab-Group_390','tab_Ellipse_121','tab-Dot-4','tab-Path_602','process-des-2')}>
                <g id="Group_388-2" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-7" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 536.88, 153.36)" filter="url(#Polygon_tab_1-2)">
                      <path id="Polygon_1-8" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(479.75 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2-2" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#41d692" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_2" data-name="#2" transform="translate(294 1652)" fill="#41d692" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-14.81" y="0">#2</tspan></text>
                <text id="Design_Development" data-name="Design
          &amp;
          Development" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-30.392" y="0">Design</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-59.608" y="50">Development</tspan></text>
              </g>
              <g id="tab-Group_391" data-name="Group 391" transform="translate(353 498.697)" onClick={(e)=>showDescription('tab-Group_391','tab_Ellipse_128','tab-Dot-3','tab-Path_695','process-des-3')}>
                <g id="Group_388-3" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-9" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 537.05, -198.33)" filter="url(#Polygon_tab_1-3)">
                      <path id="Polygon_1-10" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(479.58 353.64) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2-3" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#15ceff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_3" data-name="#3" transform="translate(294 1652)" fill="#15ceff" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-15.06" y="0">#3</tspan></text>
                <text id="Testing_Deployment" data-name="Testing
          &amp;
          Deployment" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-32.952" y="0">Testing</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-54.328" y="50">Deployment</tspan></text>
              </g>
              <g id="tab-Group_392" data-name="Group 392" transform="translate(82 498.697)" onClick={(e)=>showDescription('tab-Group_392','tab_Ellipse_127','tab-Dot-2','tab-Path_694','process-des-4')}>
                <g id="Group_388-4" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-11" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 808.05, -198.33)" filter="url(#Polygon_tab_1-4)">
                      <path id="Polygon_1-12" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(208.58 353.64) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2-4" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#41d692" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_4" data-name="#4" transform="translate(294 1652)" fill="#41d692" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-15.68" y="0">#4</tspan></text>
                <text id="Maintenance_Support" data-name="Maintenance
          &amp;
          Support" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-58.688" y="0">Maintenance</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-35.344" y="50">Support</tspan></text>
              </g>
              <path id="tab-Path_602" data-name="Path 602" d="M0,0H242.607" transform="translate(391.5 2015.5)" fill="none" stroke="#ff9d00" strokeLinecap="round" strokeWidth="2" strokeDasharray="3 10"/>
              <path id="tab-Path_694" data-name="Path 694" d="M0,0H242.607" transform="translate(391.5 2069.5)" fill="none" stroke="#ff9d00" strokeLinecap="round" strokeWidth="2" strokeDasharray="3 10"/>
              <circle id="tab_Ellipse_120" data-name="Ellipse 120" cx="7" cy="7" r="7" transform="translate(370 2008)" fill="#ff9d00"/>
              <circle id="tab_Ellipse_127" data-name="Ellipse 127" cx="7" cy="7" r="7" transform="translate(370 2062)" fill="#ff9d00"/>
              <circle id="tab_Ellipse_121" data-name="Ellipse 121" cx="7" cy="7" r="7" transform="translate(641 2008)" fill="#ff9d00"/>
              <circle id="tab_Ellipse_128" data-name="Ellipse 128" cx="7" cy="7" r="7" transform="translate(641 2062)" fill="#ff9d00"/>
              <path id="tab-Path_695" data-name="Path 695" d="M0,0H35.46" transform="translate(648 2025.045) rotate(90)" fill="none" stroke="#ff9d00" strokeLinecap="round" strokeWidth="2" strokeDasharray="3 10"/>
              <g id="tab_Dot" transform="translate(366 2004)" fill="none" stroke="#ff9d00" strokeWidth="1">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.5" fill="none"/>
              </g>
              <g id="tab-Dot-2" data-name="Dot" transform="translate(366 2058)" fill="none" stroke="#ff9d00" strokeWidth="1">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.5" fill="none"/>
              </g>
              <g id="tab-Dot-3" data-name="Dot" transform="translate(637 2058)" fill="none" stroke="#ff9d00" strokeWidth="1">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.5" fill="none"/>
              </g>
              <g id="tab-Dot-4" data-name="Dot" transform="translate(637 2004)" fill="none" stroke="#ff9d00" strokeWidth="1">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.5" fill="none"/>
              </g>
            </g>
          </svg>
          
        </div>
        <div className="our-process-desk-view">
          <svg id="our-process-method-desk" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"  height="279.135" viewBox="0 0 1025.663 279.135">
            <defs>
              <filter id="Polygon_desk_1" x="4.53" y="1.943" width="204.052" height="235.081" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="5" result="blur"/>
                <feFlood floodOpacity="0.161" result="color"/>
                <feComposite operator="out" in="SourceGraphic" in2="blur"/>
                <feComposite operator="in" in="color"/>
                <feComposite operator="in" in2="SourceGraphic"/>
              </filter>
              <filter id="Polygon_desk_1-2" x="275.53" y="1.943" width="204.052" height="235.081" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="5" result="blur-2"/>
                <feFlood floodOpacity="0.161" result="color-2"/>
                <feComposite operator="out" in="SourceGraphic" in2="blur-2"/>
                <feComposite operator="in" in="color-2"/>
                <feComposite operator="in" in2="SourceGraphic"/>
              </filter>
              <filter id="Polygon_desk_1-3" x="546.53" y="1.943" width="204.052" height="235.081" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="5" result="blur-3"/>
                <feFlood floodOpacity="0.161" result="color-3"/>
                <feComposite operator="out" in="SourceGraphic" in2="blur-3"/>
                <feComposite operator="in" in="color-3"/>
                <feComposite operator="in" in2="SourceGraphic"/>
              </filter>
              <filter id="Polygon_desk_1-4" x="817.53" y="1.943" width="204.052" height="235.081" filterUnits="userSpaceOnUse">
                <feOffset input="SourceAlpha"/>
                <feGaussianBlur stdDeviation="5" result="blur-4"/>
                <feFlood floodOpacity="0.161" result="color-4"/>
                <feComposite operator="out" in="SourceGraphic" in2="blur-4"/>
                <feComposite operator="in" in="color-4"/>
                <feComposite operator="in" in2="SourceGraphic"/>
              </filter>
            </defs>
            <g id="Group_570" data-name="Group 570" transform="translate(-170 -1646.865)">
              <g id="Scoping_Estimation" data-name="Scoping &amp; Estimation" onClick={(e)=>showDescription('Scoping_Estimation','desk-Dot-2','desk-Dot','','process-des-1')}>
                <g id="Group_569" data-name="Group 569">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-5" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(378.582 1648.808) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 170, 1646.86)" filter="url(#Polygon_desk_1)">
                      <path id="Polygon_1-6" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(208.58 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(381.663 1645) rotate(90)" fill="none" stroke="#15ceff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_1" data-name="#1" transform="translate(276 1699)" fill="#15ceff" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-12.69" y="0">#1</tspan></text>
                <text id="Scoping_Estimation-2" data-name="Scoping
          &amp;
          Estimation" transform="translate(276 1747)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-35.872" y="0">Scoping</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-48" y="50">Estimation</tspan></text>
              </g>
              <g id="Design_Development_" data-name="Design &amp; Development " transform="translate(253 47)" onClick={(e)=>showDescription('Design_Development_','desk_Ellipse_119','desk-Dot-3','desk_Dotted_Line','process-des-2')}>
                <g id="Group_388" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-7" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 537.05, 153.36)" filter="url(#Polygon_desk_1-2)">
                      <path id="Polygon_1-8" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(479.58 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2-2" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#41d692" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_2" data-name="#2" transform="translate(294 1652)" fill="#41d692" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-14.81" y="0">#2</tspan></text>
                <text id="Design_Development" data-name="Design
          &amp;
          Development" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-30.392" y="0">Design</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-59.608" y="50">Development</tspan></text>
              </g>
              <g id="Testing_Deployment_" data-name="Testing &amp; Deployment " transform="translate(524 47)" onClick={(e)=>showDescription('Testing_Deployment_','desk_Ellipse_120','desk-Dot-4','desk_Dotted_Line-2','process-des-3')}>
                <g id="Group_388-2" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-9" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, 266.05, 153.36)" filter="url(#Polygon_desk_1-3)">
                      <path id="Polygon_1-10" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(750.58 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2-3" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#15ceff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_3" data-name="#3" transform="translate(294 1652)" fill="#15ceff" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-15.06" y="0">#3</tspan></text>
                <text id="Testing_Deployment" data-name="Testing
          &amp;
          Deployment" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-32.952" y="0">Testing</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-54.328" y="50">Deployment</tspan></text>
              </g>
              <g id="Maintenance_Support_" data-name="Maintenance &amp; Support" transform="translate(795 47)" onClick={(e)=>showDescription('Maintenance_Support_','desk_Ellipse_121','desk-Dot-5','desk_Dotted_Line-3','process-des-4')}>
                <g id="Group_388-3" data-name="Group 388" transform="translate(-620.047 1446.5)">
                  <g data-type="innerShadowGroup">
                    <path id="Polygon_1-11" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1016.629 155.308) rotate(90)" fill="#fff"/>
                    <g transform="matrix(1, 0, 0, 1, -4.95, 153.36)" filter="url(#Polygon_desk_1-4)">
                      <path id="Polygon_1-12" data-name="Polygon 1" d="M164.751,0a20,20,0,0,1,17.33,10.017l47.25,82.026a20,20,0,0,1,0,19.966l-47.25,82.026a20,20,0,0,1-17.33,10.017H70.331A20,20,0,0,1,53,194.035L5.75,112.009a20,20,0,0,1,0-19.966L53,10.017A20,20,0,0,1,70.331,0Z" transform="translate(1021.58 1.94) rotate(90)" fill="#fff"/>
                    </g>
                  </g>
                  <path id="Polygon_2-4" data-name="Polygon 2" d="M170.463,0a20,20,0,0,1,17.33,10.017l49.154,85.331a20,20,0,0,1,0,19.966l-49.154,85.331a20,20,0,0,1-17.33,10.017H72.234A20,20,0,0,1,54.9,200.646L5.75,115.314a20,20,0,0,1,0-19.966L54.9,10.017A20,20,0,0,1,72.235,0Z" transform="translate(1019.709 151.5) rotate(90)" fill="none" stroke="#41d692" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" strokeDasharray="3 10"/>
                </g>
                <text id="_4" data-name="#4" transform="translate(294 1652)" fill="#41d692" fontSize="20" fontFamily="Poppins-SemiBold, Poppins" fontWeight="600" letterSpacing="0.02em"><tspan x="-15.68" y="0">#4</tspan></text>
                <text id="Maintenance_Support-2" data-name="Maintenance
          &amp;
          Support" transform="translate(294 1700)" fontSize="16" fontFamily="Poppins-Bold, Poppins" fontWeight="700" letterSpacing="0.05em"><tspan x="-58.688" y="0">Maintenance</tspan><tspan x="-6.384" y="25">&amp;</tspan><tspan x="-35.344" y="50">Support</tspan></text>
              </g>
              <path id="desk_Dotted_Line" data-name="Dotted Line" d="M0,0H225.074" transform="translate(298.5 1915.5)" fill="none" stroke="#ff9d00" strokeLinecap="round" strokeWidth="2" strokeDasharray="3 10"/>
              <circle id="desk-Dot" cx="7" cy="7" r="7" transform="translate(269 1908)" fill="#ff9d00"/>
              <circle id="desk_Ellipse_119" data-name="Ellipse 119" cx="7" cy="7" r="7" transform="translate(540 1908)" fill="#ff9d00"/>
              <circle id="desk_Ellipse_120" data-name="Ellipse 120" cx="7" cy="7" r="7" transform="translate(811 1908)" fill="#ff9d00"/>
              <circle id="desk_Ellipse_121" data-name="Ellipse 121" cx="7" cy="7" r="7" transform="translate(1082 1908)" fill="#ff9d00"/>
              <g id="desk-Dot-2" data-name="Dot" transform="translate(265 1904)" fill="none" stroke="#ff9d00" strokeWidth="1">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.5" fill="none"/>
              </g>
              <g id="desk-Dot-3" data-name="Dot" transform="translate(536 1904)" fill="none" stroke="#ff9d00" strokeWidth="1">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.5" fill="none"/>
              </g>
              <g id="desk-Dot-4" data-name="Dot" transform="translate(807 1904)" fill="none" stroke="#ff9d00" strokeWidth="1">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.5" fill="none"/>
              </g>
              <g id="desk-Dot-5" data-name="Dot" transform="translate(1078 1904)" fill="none" stroke="#ff9d00" strokeWidth="1">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.5" fill="none"/>
              </g>
              <path id="desk_Dotted_Line-2" data-name="Dotted Line" d="M0,0H224.9" transform="translate(570.5 1915.5)" fill="none" stroke="#ff9d00" strokeLinecap="round" strokeWidth="2" strokeDasharray="3 10"/>
              <path id="desk_Dotted_Line-3" data-name="Dotted Line" d="M0,0H225.032" transform="translate(840.5 1915.5)" fill="none" stroke="#ff9d00" strokeLinecap="round" strokeWidth="2" strokeDasharray="3 10"/>
            </g>
          </svg>
               
        </div>
        <div className="process-des-container">
            <div id="process-des-1" className="process-description">
                <h3 className="sub-headline-txt">We understand your idea & needs</h3>
                <p className="body-txt-5">First things first! We Identify the scope of your<br/>
                   project and study your plans & expectations to build<br/>
                    a better vision of your idea . Then we assess your<br/>
                    project to compose a project proposal document &<br/>
                    we submit it to you for your review.</p>
                <p className="tab-desk-body-txt-5">First things first! We Identify the scope of your project and study<br/>
                  your plans & expectations to build a better vision of your idea.<br/>
                  Then we assess your project to compose a project proposal<br/> 
                  document & we submit it to you for your review.</p>
            </div>
            <div id="process-des-2" className="process-description" style={{display:'none'}}>
                <h3 className="sub-headline-txt">We bring life to your idea</h3>
                <p className="body-txt-5">We kickstart your project according to your<br/>
                   accepted action plan. We keep your idea in the<br/>
                    Frame and design your project in a unique manner<br/>
                    and submit you a design prototype. With your<br/> 
                    design concerns, we step to the development<br/>
                    process and keep you updated.</p>
                  <p className="tab-desk-body-txt-5">We kickstart your project according to your accepted action plan.<br/> 
                    We keep your idea in the Frame and design your project in a<br/> 
                    unique manner and submit you a design prototype. With your<br/>
                       design concerns, we step to the development process and keep<br/> you updated.
                    </p>
            </div>
            <div id="process-des-3" className="process-description" style={{display:'none'}}>
                <h3 className="sub-headline-txt">We introduce your product to the market</h3>
                <p className="body-txt-5">Time to shine! We conduct a thorough assessment<br/>
                   to your product to ensure its quality before release it<br/>
                    to the market. With a strong results from our QA<br/>
                     team, then we conduct your product's deployment<br/>
                      process. We make sure that every individual system<br/>
                      is deployed and works perfectly.</p>
                <p className="tab-desk-body-txt-5">Time to shine! We conduct a thorough assessment to your<br/> 
                  product to ensure its quality before release it to the market. With a<br/> 
                  strong results from our QA team, then we conduct your product's<br/> 
                  deployment process. We make sure that every individual system<br/>
                  is deployed and works perfectly.
                </p>
            </div>
            <div id="process-des-4" className="process-description" style={{display:'none'}}>
                <h3 className="sub-headline-txt">We take care of your product</h3>
                <p className="body-txt-5">We provide you an ongoing support once your<br/>
                   product is live and make sure your production<br/>
                    environment’s overall performance.</p>
                <p className="tab-desk-body-txt-5">We provide you an ongoing support once your product is live and<br/>
                   make sure your production environment's overall performance.
                  </p>
            </div>
        </div>
    </div>
  
        </div>
    );
}