import Weare from "../components/Weare";
import OurProcess from "../components/OurProcess";
import OurProject from "../components/OurProject";
export default function WeAre(){
    return(
        <div>
            <Weare/>
            <OurProcess/>
            <OurProject/>
        </div>
    );
}