import nock_img from '../images/knock/undraw_real_time_collaboration_c62i.svg';
export default function MobTabKnock(){
    return(
        <div id="mob-tab-knock-popup">
        <img src={nock_img} alt=""/>
        <p>Hey 👋 , We are looking forward<br/> 
            to hear your message! We have<br/>
            prepared here to assist and power-up<br/>
            your journey 😀 . Drop us a text<br/>
            via our WhatsApp & We will<br/>
            touch you back soon 🤟 !</p>
        <div>
            <a href="https://wa.me/message/DWHRSHNA7ZJKC1">To WhatsApp</a>
        </div>
        </div>
    );
}