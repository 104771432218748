import img_1 from '../images/whoweare/Our Services/Group 541.svg';
import img_2 from '../images/whoweare/Our Services/Group 384.svg';
import img_3 from '../images/whoweare/Our Services/Group 541.svg';
export default function Weare(){
    return(
        <div className="whoweare-main">
    <div className="whoweare-container">
        <h2 className="headline-txt">Who we are!</h2>
        <div className="whoweare-wrapper">
            <div>
                <img src={img_1} alt=""/>
            </div>
            <div>
                <p className="body-txt">
                  THAAYAKAM LTD is a start-up, founded in 2021 with the sole intention to provide a wide range of high-tech solutions incorporated with emerging technologies. We are close knits of passionate tech working towards modern technologies to solve complex real-world problems. Our crew, which works towards new heights, provides high-quality solutions and services with solid commitment. By joining the lightning-fast journey towards the information age to create new digitalised environments, THAAYAKAM LTD will explore a new era full of innovation.
                </p>
            </div>
           
        </div>
       
    </div>

    <div className="whatwedo-container">
        <h2 className="headline-txt">What we do!</h2>
        <div className="whatwedo-wrapper">
            <div>
                <img src={img_2} alt=""/>
            </div>
            <div>
                <p className="body-txt">
                  Our professionals, packed with deep technical knowledge and broad industrial experience, work close to the client's perspective to get the job to reach the desired end. As an information technology solutions provider, the focus of THAAYAKAM LTD's offered services are towards the leading and most consuming domains in the industry. Currently, THAAYAKAM LTD provides Cloud Consultancy, Web & Mobile Application Development, and Brand Design solutions. We have planned to expand our services to Information security, Blockchain, and Data Analytics in the near future.</p>
            </div>
           
        </div>
    </div>
    
    <div className="future-container">
        <h2 className="headline-txt">Our future</h2>
        <div className="future-wrapper">
            <div >
                <img src={img_3} alt=""/>
            </div>
            <div>
                <p className="body-txt">By understanding the changes in technologies, global regulations, and environmental habits, THAAYAKAM LTD will continue to adapt and forge ways to keep up with the dynamics of the ever-changing world through two levels of objectives.
                </p>
                <p className="body-txt" style={{fontWeight:500}}><br/>1. Research and Productize innovations to the global market.</p>
                     <p className="body-txt" style={{fontWeight:500}}>2. Ahead of an era without digital divide by increasing the affordability and spread awareness.
                </p>
                
            </div>
        </div>
    </div>
    </div>
    );
}