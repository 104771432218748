import { Link } from 'react-router-dom';
import {loadVisitedLink} from '../script'
import logo1 from '../logo-1.svg';
function Footer(){

const Subscribe=()=>{
  let emailValue=document.getElementById('submit-icon').value;
  let emailValid = emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  let email = emailValid ? '' : 'invalid email';
  if(!email){
    document.getElementById('subs-txt-container').style.display='block';
    document.getElementById('subs-popup').style.display='block';
    document.getElementById('loader').style.display='block';
    document.getElementById('contact-txt').style.display='none';
    document.getElementById('newsletter-txt').style.display='none';
    
    fetch('https://thayakam-api.herokuapp.com/subscribe', {
      method: 'POST',
      body: JSON.stringify({email: emailValue}),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      
    })
       .then(response => response.json())
       .then((data) => {
          console.log('got');
          console.log(data.msg);
            document.getElementById('subs-txt-container').style.display='block';
            document.getElementById('loader').style.display='none';
            document.getElementById('newsletter-txt').style.display='block';
            document.getElementById('newsletter-txt').innerHTML=data.msg;
  
  
       
       })
       .catch((err) => {
        console.log('i am error');
        console.log(err.message);
      
       });
  }
  else{
    document.getElementById('submit-icon').value=email;
  }
  
}
    return (
        <div id="footer-bar-container">
        <footer>
            <div className="footer-container">
                <div className="footer-item1">
                <img src={logo1} alt="" width="100px"/>
                  <p className="footer-txt footer-txt-1">THAAYAKAM LTD is a start-up, founded in 2021 with <br/>the sole intention to provide a wide range of <br/>
                        high-tech solutions incorporated <br/>
                         with emerging technologies.</p>
                </div>
                <div className="footer-item2">
                    <h4 className="footer-txt footer-txt-4">Company</h4>
                    <ul className="footer-list">
                        <li className="footer-txt footer-txt-5">
                          <Link to="services" onClick={(e)=>loadVisitedLink('nav-link-','nav-link',3)}>Services</Link>
                        </li>
                         <li className="footer-txt footer-txt-5">
                          <Link to="we-are" onClick={(e)=>loadVisitedLink('nav-link-','nav-link',2)}>We Are</Link>
                        </li>
                        <li className="footer-txt footer-txt-5">
                          <Link to='contact' onClick={(e)=>loadVisitedLink('nav-link-','nav-link',5)}>Quote?</Link>
                          </li> 
                    </ul>
                </div>
                <div className="footer-item3">
                    <h4 className="footer-txt footer-txt-4">Our Platforms</h4>
                    <ul className="footer-list">
                        <li className="footer-txt footer-txt-5"><a href="https://www.behance.net/thaayakamltd" target="_blank" rel="noreferrer">Behance</a></li>
                        <li className="footer-txt footer-txt-5">Medium</li>
                        <li className="footer-txt footer-txt-5">UpWork</li>
                    </ul>
                </div>
                <div className="footer-txt footer-item4">
                    <h4 className="footer-txt footer-txt-4">Follow</h4>
                    <ul className="footer-list">
                      <li className="footer-txt footer-txt-5"> <a href="https://www.linkedin.com/company/thaayakamltd" target="_blank" rel="noreferrer" >LinkedIn</a></li>
                      <li className="footer-txt footer-txt-5"><a href="https://www.facebook.com/thaayakamltd" target="_blank" rel="noreferrer" >Facebook</a></li>
                      <li className="footer-txt footer-txt-5"><a href="https://www.instagram.com/thaayakamltd" target="_blank" rel="noreferrer" >Instagram</a></li>
                    </ul>
                </div>
                <div className="footer-item5">
                    <h4 className="footer-txt footer-txt-4">Contact</h4>
                    <ul className="footer-list">
                      <li className="footer-txt footer-txt-5"><a href="mailto:hello@thaayakam.co.uk">hello@thaayakam.co.uk</a></li>
                        <li className="footer-txt footer-txt-5"><a href="mailto:careers@thaayakam.co.uk">careers@thaayakam.co.uk</a></li>
                        <li className="footer-txt footer-txt-5"><a href="https://wa.me/message/DWHRSHNA7ZJKC1" target="_blank" rel="noreferrer">(+44) 7443 557732 </a></li>
                    </ul>
                </div>
                <div className="footer-item6">
                    <span className="footer-txt footer-txt-2">Our snippets. Your inbox</span>
                      <input id="submit-icon" type="email" name=""  placeholder="Email address"/>
                      <span id="email-submit-icon" style={{cursor:'pointer'}} onClick={Subscribe}>
                        <svg  xmlns="http://www.w3.org/2000/svg" width="36.373" height="30.618" viewBox="0 0 36.373 30.618">
                          <path id="Icon_material-send" data-name="Icon material-send" className="email-cls-1" style={{fill:'#15ceff'}} d="M3.017,35.118,39.373,19.809,3.017,4.5,3,16.407l25.98,3.4L3,23.211Z" transform="translate(-3 -4.5)"/>
                        </svg>
                      </span>
                    
                </div>
                <div className="footer-item7">
                    <Link to="privacy"><span >Data privacy & Policy</span></Link>
                </div>
                <div className="footer-item8">
                    <span className="footer-txt footer-txt-3">COPYRIGHT 2022 &copy; THAAYAKAM LTD</span>
                </div>
            </div>
        </footer>
        </div> 
    );
}

export default Footer;
